import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from "./assets/logo.png";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useState , useEffect } from 'react';
import Web3 from "web3";
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import getAbi from './ABI';
import getUSDTAbi from './ABI/USDT';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Wallet } from 'react-bootstrap-icons';
import Alert from '@mui/material/Alert';
//import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

function App() {
  const [amm, setAmm] = useState(0);
const [day, setDay] = useState(0);
const [countdown, setCountdown] = useState(null);

const [web3Modal, setWeb3Modal] = useState(null);
// eslint-disable-next-line
const [provider, setProvider] = useState(null);
const [btntext, setBtnText] = useState('Connect');
const [current, setCurrent] = useState(null);
const [web3, setWeb3] = useState();
const [abi, setAbi] = useState();
const [usdtAbi,setUSDTABI] = useState();
const [isConnected, setIsConnected] = useState(false);
// eslint-disable-next-line
const [injectedProvider, setInjectedProvider] = useState();
const [refetch, setRefetch] = useState(true);
const [refLink, setRefLink] = useState(
  'https://titance.app/?ref=0x0000000000000000000000000000000000000000'
);
const [invested, setInvested] = useState(0);
const [ROI,setROI] = useState(0);
const [value,setValue] = useState(0);
const [bonus,setBonus] = useState(0);
const [TVL,setTVL] = useState(0);
const [packageR,setPackage] = useState(0);
const [total_withdrawn,setTotalWithdrawn] = useState(0);
const [totalBonus,setBonusTotal] = useState(0);
const [copyPaste,setCopyPaste] = useState(0);
const [balance,setBalance] = useState(0);
const [chainId,setChainId] = useState(0);
const [allowance,setAllowance] = useState(0);
const [totalRewardWithdrawn,setTotalRewardWithdrawn] = useState(0);
const [message,setMessage] = useState('');
const [popup,setPopup] = useState(false);


const queryParams = new URLSearchParams(window.location.search);
let DefaultLink = queryParams.get("ref");


if (DefaultLink === null) {
  DefaultLink = "0x6537aB79cbd0bBD8Bf25B4C9D06b67Ff79cB8d98";
}

useEffect(() => {
  // initiate web3modal
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          56: 'https://bsc-dataseed.binance.org'
        }
      }
    }
  };

  const newWeb3Modal = new Web3Modal({
    cacheProvider: true, // very important
    providerOptions
  });

  setWeb3Modal(newWeb3Modal);
}, []);

const shortenAddr = addr => {
  if (!addr) return '';
  const first = addr.substr(0, 3);
  const last = addr.substr(38, 41);
  return first + '...' + last;
};



const connectWallet = async () => {
  await web3Modal.clearCachedProvider();
  const currentProvider = await web3Modal.connect();
  const web3 = new Web3(currentProvider);
  const accounts = await web3.eth.getAccounts();
  const acc = accounts[0];
  setCurrent(acc);
  setWeb3(web3);
  setAbi(await getAbi(new Web3(currentProvider)));
  setUSDTABI(await getUSDTAbi(new Web3(currentProvider)));
  setIsConnected(true);
  const short = shortenAddr(acc);
  const chain = await web3.eth.getChainId();
  
  setChainId(chain);
  if(chain === 56 ) {
  setBtnText(short);
}
else {
  setBtnText("Wrong Network")
}
  setInjectedProvider(new Web3(currentProvider)); // add this line to set the injected provider

  currentProvider.on('chainChanged', chainId => {
    console.log(`chain changed to ${chainId}! updating providers`);
    setInjectedProvider(new Web3(currentProvider));
  });

  currentProvider.on('accountsChanged', () => {
    console.log('account changed!');
    setInjectedProvider(new Web3(currentProvider));
  });
};





useEffect(() => {
  const interval = setInterval(() => {
    setRefetch(prevRefetch => !prevRefetch);
  }, 10000);

  return () => clearInterval(interval);
}, []);

useEffect(() => {
  const refData = async () => {
    if (isConnected && web3) {
      const refLink = 'https://titance.app/?ref=' + current;
      setRefLink(refLink);
    }
  };

  refData();
}, [isConnected, current, web3, refetch]);

const getInvestedAmount = async () => {
  if (isConnected && abi) {
    const deposit = await abi.methods.myUser(current).call();
    setInvested(deposit.invested / 1e18);
    setPackage(Number(deposit.isNFT));
    const dailyRoi = await abi.methods.ROI_NOW(current).call();
    setROI(dailyRoi / 1e18)
   
    setTotalWithdrawn(deposit.total_withdrawn / 1e18);
    

    const bonus = await abi.methods.myReferral(current).call();
    setBonus(bonus.totalreward / 1e18);
    setBonusTotal(bonus.totalWithdrawn / 1e18);
    const TVL = await abi.methods.myTVL().call();
    setTVL(TVL / 1e18);
    const totalRewardWithdrawn = await abi.methods.total_withdrawn().call();
    setTotalRewardWithdrawn(totalRewardWithdrawn / 1e18);

 
  

  }
};

const USDTPortfolio = async () => {
  if (isConnected && usdtAbi) {
  const balance = await usdtAbi.methods.balanceOf(current).call();
  setBalance(balance / 1e18);
  const allowance = await usdtAbi.methods.allowance(current,'0x2FC63d3cA5946a3c5b07B5Ad21740613ffCD852d').call();
  setAllowance(allowance / 1e18);
  }
}

useEffect(() => {
  getInvestedAmount();
  USDTPortfolio();
  // eslint-disable-next-line
}, [isConnected, web3, abi, usdtAbi, refetch]);




const Deposit = async (e) => {
  e.preventDefault()
  setPopup(true);
  setMessage("Depositing USDT...")
  if(isConnected) {
    if(chainId === 56) {
  let _value = await web3.utils.toWei(value);
  await abi.methods.deposit(DefaultLink,_value).send({
   from: current,
  })
  setMessage("Deposited USDT Successful!")
  getInvestedAmount();
  USDTPortfolio();
}
else {
  console.log("Wrong Network");
}
 
}
else {
  connectWallet();
}

}

const Approve = async (e) => {
  e.preventDefault()
  setPopup(true);
  setMessage("Approving USDT...")
  if(isConnected) {
    if(chainId === 56) {
  let _value = await web3.utils.toWei(value);
  await usdtAbi.methods.approve('0x2FC63d3cA5946a3c5b07B5Ad21740613ffCD852d',_value).send({
   from: current,
  })
  setMessage("Approved Successful!")
  getInvestedAmount();
  USDTPortfolio();
}
else {
  console.log("Wrong Network");
}
 
}
else {
  connectWallet();
}

}

const ReInvest = async (e) => {
  e.preventDefault()
  setPopup(true);
  setMessage("Re-Investing Rewards....")
  if(isConnected) {
  await abi.methods.reinvest().send({
   from: current,
  })
  setMessage("Re-Invested Successful!")
  getInvestedAmount();
  USDTPortfolio();

}
else {
  connectWallet();
}

}

const Withdraw = async (e) => {
  e.preventDefault()
  setPopup(true);
  setMessage("Withdrawing Rewards...")
  if(isConnected) {
  await abi.methods.withdraw_reward().send({
   from: current,
  })
  setMessage("Withdrawn Successful!")
  getInvestedAmount();
  USDTPortfolio();
 
}
else {
  connectWallet();
}

}

const DWithdraw = async (e) => {
  e.preventDefault()
  setPopup(true);
  setMessage("Withdrawing Referral rewards");
  if(isConnected) {
  await abi.methods.directWithdrawRef().send({
   from: current,
  })
  setMessage("Successfully Withdrawn!")
  getInvestedAmount();
  USDTPortfolio();
  
}
else {
  connectWallet();
}

}

const DevilPower = async (e) => {
  e.preventDefault()
  setPopup(true);
  setMessage("BUYING DOLLAR BABY NFT.....")
  if(isConnected) {
  let _value = await web3.utils.toWei('100');
  await abi.methods.ACTIVIATE_NFT(1,_value).send({
   from: current,
  
  })
  setMessage("PURCHASED SUCCESSFUL!")
  getInvestedAmount();
  USDTPortfolio();

}
else {
  connectWallet();
}

}

const EvilPower = async (e) => {
  e.preventDefault()
  setPopup(true);
  setMessage("BUYING DOLLAR BAG NFT.....")
  if(isConnected) {
  let _value = await web3.utils.toWei('150');
  await abi.methods.ACTIVIATE_NFT(2,_value).send({
   from: current,
  })
  setMessage("PURCHASED SUCCESSFUL!")
  getInvestedAmount();
  USDTPortfolio();

}
else {
  connectWallet();
}

}



useEffect(() => {
  const countdownDate = new Date('2023-08-25T12:00:00Z').getTime(); // Set your desired countdown date and time in UTC format
  const interval = setInterval(() => {
    const now = new Date().getTime();
    const distance = countdownDate - now;
    if (distance < 0) {
      clearInterval(interval);
      setCountdown('Project Officially Launched!');
    } else {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setCountdown(`${days} DAYS - ${hours} HOURS - ${minutes} MINTS - ${seconds} - SEC`);
    }
  }, 1000);

  return () => clearInterval(interval);
}, []);






  return (
   <>
    <Navbar bg="dark" variant='dark' expand="lg">
      <Container fluid>
        <Navbar.Brand href="#"><img src={logo} alt="timeusd" width={250} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="./document.pdf" className='text text-warning'>DOCUMENTS</Nav.Link>
            <Nav.Link href="https://t.me/real_titance" className='text text-warning'>TELEGRAM</Nav.Link>
            <Nav.Link href="https://twitter.com/real_titance" className='text text-warning'>TWITTER</Nav.Link>
            <Nav.Link href="https://bscscan.com/address/0x2FC63d3cA5946a3c5b07B5Ad21740613ffCD852d#code" className='text text-warning'>CONTRACT</Nav.Link>

            <Nav.Link href="#" className='text text-warning'>AUDIT</Nav.Link>
           
           
          </Nav>
          <div className="d-flex">
          
            <Button variant="outline-warning" onClick={connectWallet}><Wallet /> {btntext}</Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>

   
   
<div className='container'>

  <br /><div className='alert bg-dark'>

  <center><h1 className='text text-warning'>LAUNCH IN: </h1>
      <h1 className='text text-white'>{countdown}</h1></center></div> <br />
      <div className='col-sm-12'><h3 style={{color:"#fff",textShadow:"10px black"}}> The First Stable Miner on Binance Smart Chain. Earn Up To <span style={{color:"#F7B50B",fontWeight:"bold"}}>0.5% to 1% </span> & Invite Others To Join and Earn 5% Of Their Investment.</h3> </div>

 
<div className='row'>
<div className='col-sm-4'>
 <div className='card bg-dark text-white'>
  <div className='card-header bg-warning'>
   <h3> <span style={{fontWeight:"bold"}} className='text-dark'>TOTAL INVESTMENT</span></h3>
  </div>
  <div className='card-body'>
  <h1>  {TVL} USDT</h1>
  </div>
 </div>

  </div>
 <div className='col-sm-4'>
 <div className='card bg-dark text-white'>
  <div className='card-header bg-warning'>
   <h3> <span style={{fontWeight:"bold"}} className='text-dark'>TVL</span></h3>
  </div>
  <div className='card-body'>
  <h1>  {TVL} USDT</h1>
  </div>
 </div>

  </div>
  <div className='col-sm-4'>

  <div className='card bg-dark text-white'>
  <div className='card-header bg-warning'>
  <h3><span style={{fontWeight:"bold"}} className='text-dark'> REWARDS WITHDRAWN </span></h3>
  </div>
  <div className='card-body'>
   <h1> {Number(totalRewardWithdrawn).toFixed(2)} USDT</h1>
  </div>
 </div>
  </div>
</div>
<br />
<div className='row'>

  <div className='col-sm-4'>

    <div className='card bg-dark text-white' style={{height:"100%"}}>
      <div className='card-header bg-warning'>
      <h3><span style={{fontWeight:"bold"}} className='text-dark'>  DEPOSITS </span></h3>
      </div>
      <div className='card-body'>
      <table className='table table-borderless text-white'>
        <tbody>
          <tr>
            <td>USDT BALANCE</td>
            <td style={{textAlign:"right"}}>{Number(balance).toFixed(4)} USDT</td>
          </tr>
          <tr>
            <td>INVESTED</td>
            <td style={{textAlign:"right"}}>{Number(invested).toFixed(4)} USDT</td>
          </tr>

          <tr>
            <td>CURRENT ROI</td>
            <td style={{textAlign:"right"}}>{packageR === 0? <>0.5</> : packageR === 1? <>0.8</> : <>1</>}%</td>
          </tr>

          <tr>
            <td>FEES</td>
            <td style={{textAlign:"right"}}>5%</td>
          </tr>
          <tr>
            <td>Approved</td>
            <td style={{textAlign:"right"}}> {allowance} USDT</td>
          </tr>
       

          <tr>
            <td>MIN DEPOSIT</td>
            <td style={{textAlign:"right"}}>30 USDT</td>
          </tr>

         
         </tbody>
         </table>
        <input type='number' placeholder='30 USDT' className='form-control'
            onChange={(e) => setValue(e.target.value)} 
             />
             <table className='table'>
              <tbody>
                <tr>
                <td style={{textAlign:"right"}}><button className='btn btn-warning' onClick={Approve} style={{marginBottom:"5px"}}>1: Approve</button></td>
        <td> <button className='btn btn-warning' onClick={Deposit} style={{marginBottom:"5px"}}>2: Deposit</button></td>
                </tr>
              </tbody>
             </table>
      
         </div>
       

    </div>
   </div>
   <div className='col-sm-4'>

    <div className='card bg-dark text-white' style={{height:"100%"}}>
      <div className='card-header bg-warning'>
     <h3> <span style={{fontWeight:"bold"}} className='text-dark'>  REWARDS </span></h3>
      </div>
      <div className='card-body text-white'>
      <table className='table table-borderless text-white'>
        <tbody>
          <tr>
            <td>Daily Rewards</td>
            <td style={{textAlign:"right"}}>{Number(ROI).toFixed(4)} USDT</td>
          </tr>
          <tr>
            <td>Action</td>
            <td style={{textAlign:"right"}}><button className='btn btn-warning' onClick={ReInvest} style={{marginBottom:"5px"}}>Re-Invest</button> <button className='btn btn-warning' onClick={Withdraw} style={{marginBottom:"5px"}}>Withdraw</button></td>
          </tr>

          <tr>
            <td>Direct Referral Reward</td>
            <td style={{textAlign:"right"}}>{Number(bonus).toFixed(4)} USDT</td>
          </tr>

          <tr>
            <td>Action</td>
            <td style={{textAlign:"right"}}><button className='btn btn-warning' onClick={DWithdraw}>Withdraw</button></td>
          </tr>

          <tr>
            <td>Total Withdrawn</td>
            <td style={{textAlign:"right"}}>{Number(totalBonus + total_withdrawn).toFixed(4)} USDT</td>
          </tr>

        
         </tbody>
         </table>
         </div>
       

    </div>

    </div>
    <div className='col-sm-4'>

<div className='card bg-dark text-white' style={{height:"100%"}}>
  <div className='card-header bg-warning'>
 <h3> <span style={{fontWeight:"bold"}} className='text-dark'>  NFTS </span></h3>
  </div>
  <div className='card-body text-white'>
  <table className='table table-borderless text-white'>
    <tbody>
      <tr>
        <td>Dollar Baby Power</td>
        <td style={{textAlign:"right"}}><button className='btn btn-warning' onClick={DevilPower}>ACTIVIATE</button></td>
      </tr>
      <tr>
        <td>Dollar Bag Power</td>
        <td style={{textAlign:"right"}}> <button className='btn btn-warning' onClick={EvilPower}>ACTIVIATE</button></td>
      </tr>



    
     </tbody>
     </table>

     <h5>PRICES</h5>
    
     <table className='table table-borderless text-white'>
    <tbody>
      <tr>
        <td>Dollar Baby Power</td>
        <td style={{textAlign:"right"}}>100 USDT</td>
      </tr>
      <tr>
        <td>Dollar Bag Power</td>
        <td style={{textAlign:"right"}}>150 USDT</td>
      </tr>



    
     </tbody>
     </table>
     </div>
   

</div>
  </div>

  <div className='col-sm-12'>
    <br />
    <div className='card bg-dark text-white'>
      <div className='card-header bg-warning'>
     <h3> <span style={{fontWeight:"bold"}} className='text-dark'>REFERRAL </span></h3>
      </div>
      <div className='card-body'>
      <div className='row'>
        <div className='col-sm-12'>
        <input type="text" value={refLink} className='form-control'
         onChange={({target: {value}}) => setCopyPaste({value, copied: false})} 
        />
        </div>
        <div className='col-sm-12'>
       <center><br /> <CopyToClipboard text={refLink}
            onCopy={() => setCopyPaste({copied: true})}> 
            <button className='btn btn-warning'>{copyPaste ?  <span style={{color: 'red'}}> Copied</span> : "Copy"}</button>
          </CopyToClipboard></center>
         
        
          </div>      </div>
          
      <br />
      <p> <span style={{fontWeight:"bold"}}>Note:</span> Please note that to receive your referral rewards, you must make at least one deposit. If someone uses your referral link, 4% of their investment will be added to your investment, and 1% will be directly credited to your referral reward which you can withdraw directly.</p>
      
         </div>
       

    </div>
   <br />

    <div className='card bg-dark text-white'>
      <div className='card-header bg-warning'>
     <h3> <span style={{fontWeight:"bold"}} className='text-dark'> STATISTICS</span></h3>
      </div>
      <div className='card-body text-white'> <br />
     <p> <span style={{fontWeight:"bold"}}>You can earn a fixed return ranging from 0.5% to 1%. By applying powers, you can achieve the following results: </span></p>
     <p> <span style={{fontWeight:"bold"}}>Dollar Baby Power:</span> Provides a fixed return of 0.8% on your investment. The duration for Dollar Baby Power is 20 days.</p>
     <p> <span style={{fontWeight:"bold"}}>Dollar Bag Power:</span> Provides a fixed return of 1% on your investment. The duration for Dollar Bag Power is 30 days.</p>
     <p> <span style={{fontWeight:"bold"}}>NOTE:</span>When you acquire powers, the activation fee does not go to the developer, but rather to support the Total Value Locked (TVL) ecosystem.</p>
       
         </div>
       

    </div>

    <br />

<div className='card text-white' style={{background:"black"}}>
  <div className='card-header bg-warning'>
  <h3><span style={{fontWeight:"bold"}} className='text-dark'> CALCULATOR </span></h3>
  </div>
 <center> <div className='card-body text-white' style={{width:"50%"}}>
    <label>Investment</label>
  <input type="number" placeholder="0.005 USDT" className='form-control'
  onChange={(e) => setAmm(e.target.value)}
  />
  <label>Days</label>
  <input type="number" placeholder="1 Day" className='form-control'
  onChange={(e) => setDay(e.target.value)}
  />
  <br />
  <div className='row'>
    <div className='col-sm-4'>
    <div className='card bg-dark text-white'>
        <div className='card-header bg-warning fw-bold text-dark'>
          DEFAULT NFT
        </div>
        <div className='card-body'>
        {Number(amm / 100 * 0.5 * day).toFixed(4)} USDT
        </div>
      </div>
    </div>

    <div className='col-sm-4'>
    <div className='card bg-dark text-white'>
        <div className='card-header bg-warning fw-bold text-dark'>
          DOLLAR BABY NFT
        </div>
        <div className='card-body'>
        {Number(amm / 100 * 0.8 * day).toFixed(4)} USDT
        </div>
      </div>
    </div>

    <div className='col-sm-4'>
      <div className='card bg-dark text-white'>
        <div className='card-header bg-warning fw-bold text-dark'>
          DOLLAR BAG NFT
        </div>
        <div className='card-body'>
        {Number(amm / 100 * 1 * day).toFixed(4)} USDT
        </div>
      </div>
    </div>
  </div>


     </div>
     </center>
   

</div>
  </div>


</div>

</div>
{popup === true? <>
  <Stack sx={{ width: '30%', position: 'fixed', top: 0 }} spacing={2}>
          <Alert onClose={() => {setPopup(false)}} >{message}</Alert>
        </Stack>
</>: <>

</>}

   

   </>
  );
}

export default App;
